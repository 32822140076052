const db = {
    name:"Kodart Tətbiq və Təlim mərkəzi",
    logo:"1",
    courses:[
        {
            id:1,
            title:"məktəblilər-üçün-proqramlaşdırmanın-əsasları",
            name:"Məktəblilər üçün Proqramlaşdırmanın əsasları",
            image:'/Images/Courses/basics.png',
            subheader:"test",
            description:"11-16 yaş arası məktəb şagirdləri üçün nəzərdə tutulmuş tədris proqramında proqramlaşdırmanın təməlləri aşılanır.",
            subjects:["İlkin kompüter bilikləri","Alqoritmlər","Scratch","HTML","CSS", "Javascript"],
            duration:"4 ay",
            program:[
                {
                    order:"1",subject:"İlkin kompüter bilikləri", description:"",duration:""
                },
                {
                    order:"2",subject:"Scratch", description:"",duration:""
                },
                {
                    order:"3",subject:"Alqoritmlər", description:"",duration:""
                },
                {
                    order:"4",subject:"HTML", description:"",duration:""
                },
                {
                    order:"5",subject:"CSS", description:"",duration:""
                },
                {
                    order:"6",subject:"Javascript", description:"",duration:""
                }
            ]
        }
    ],
    solutions:[
        {title:"Veb saytlar",subtitle:"",description:"",order:"1",image:"./Images/Services/web.png",enabled:true},
        {title:"Xüsusi biznes həlləri",subtitle:"",description:"",order:"2",image:"./Images/Services/businesssolutions.webp",enabled:true},
        {title:"Konsaltinq",subtitle:"",description:"",order:"3",image:"./Images/Services/consulting.png",enabled:true}
    ],
    contacts:{
        phone:"50 992 00 10",
        phone_intl:"+994 50 992 0010",
        whatsapp:"+994509920010",
        eMail:"info@kodart.az",
        linkedin:"https://linkedin.com/company/kodartaz",
        instagram:"https://instagram.com/kodart.az",
        facebook:"https://facebook.com/kodart.az",
        whatsappLink:"https://wa.link/g3nct3",
        address:"Xırdalan şəhəri, Heydər Əliyev pr. 585A, Qarant Tədris Mərkəzi",
        map:"https://maps.app.goo.gl/A3gp73yoCsZzHDKK6",
        coordinates:{
            latitude:"42",
            longtitude:"45"
        }
    }
}

export default db;